import ReactFlow, {
    Edge,
    Node
  } from 'reactflow';
import { TEMPNodeContentTrackerRef, TEMPNodeData } from '../MainFlow/Flow';
import { TEMPMessageNodeState, TEMPNodeContentTrackerOption } from '../MessageNode/MessageNode';

export interface Connection {
    nodeID:      string;
    optionID?:    null | string;
    optionValue: null | string;
    nextNodeID?:  string;
}

export interface V1Node {
    id: string, 
    message: string | null, 
    connections: Connection[]
}

export interface V1Nodes {
    [index:string] : V1Node
}

export interface V1Template {
    name?: string
    start: string,
    nodes: { [index:string] : V1Node }
}

// Converts JSON strings to/from your types
// and asserts the results of JSON.parse at runtime
export class Convert {
    public static toV1Template(json: string): V1Template {
        return cast(JSON.parse(json), r("V1Template"));
    }

    public static v1TemplateToJson(value: V1Template): string {
        return JSON.stringify(uncast(value, r("V1Template")), null, 2);
    }
}

function invalidValue(typ: any, val: any, key: any = ''): never {
    if (key) {
        throw Error(`Invalid value for key "${key}". Expected type ${JSON.stringify(typ)} but got ${JSON.stringify(val)}`);
    }
    throw Error(`Invalid value ${JSON.stringify(val)} for type ${JSON.stringify(typ)}`, );
}

function jsonToJSProps(typ: any): any {
    if (typ.jsonToJS === undefined) {
        const map: any = {};
        typ.props.forEach((p: any) => map[p.json] = { key: p.js, typ: p.typ });
        typ.jsonToJS = map;
    }
    return typ.jsonToJS;
}

function jsToJSONProps(typ: any): any {
    if (typ.jsToJSON === undefined) {
        const map: any = {};
        typ.props.forEach((p: any) => map[p.js] = { key: p.json, typ: p.typ });
        typ.jsToJSON = map;
    }
    return typ.jsToJSON;
}

function transform(val: any, typ: any, getProps: any, key: any = ''): any {
    function transformPrimitive(typ: string, val: any): any {
        if (typeof typ === typeof val) return val;
        return invalidValue(typ, val, key);
    }

    function transformUnion(typs: any[], val: any): any {
        // val must validate against one typ in typs
        const l = typs.length;
        for (let i = 0; i < l; i++) {
            const typ = typs[i];
            try {
                return transform(val, typ, getProps);
            } catch (_) {}
        }
        return invalidValue(typs, val);
    }

    function transformEnum(cases: string[], val: any): any {
        if (cases.indexOf(val) !== -1) return val;
        return invalidValue(cases, val);
    }

    function transformArray(typ: any, val: any): any {
        // val must be an array with no invalid elements
        if (!Array.isArray(val)) return invalidValue("array", val);
        return val.map(el => transform(el, typ, getProps));
    }

    function transformDate(val: any): any {
        if (val === null) {
            return null;
        }
        const d = new Date(val);
        if (isNaN(d.valueOf())) {
            return invalidValue("Date", val);
        }
        return d;
    }

    function transformObject(props: { [k: string]: any }, additional: any, val: any): any {
        if (val === null || typeof val !== "object" || Array.isArray(val)) {
            return invalidValue("object", val);
        }
        const result: any = {};
        Object.getOwnPropertyNames(props).forEach(key => {
            const prop = props[key];
            const v = Object.prototype.hasOwnProperty.call(val, key) ? val[key] : undefined;
            result[prop.key] = transform(v, prop.typ, getProps, prop.key);
        });
        Object.getOwnPropertyNames(val).forEach(key => {
            if (!Object.prototype.hasOwnProperty.call(props, key)) {
                result[key] = transform(val[key], additional, getProps, key);
            }
        });
        return result;
    }

    if (typ === "any") return val;
    if (typ === null) {
        if (val === null) return val;
        return invalidValue(typ, val);
    }
    if (typ === false) return invalidValue(typ, val);
    while (typeof typ === "object" && typ.ref !== undefined) {
        typ = typeMap[typ.ref];
    }
    if (Array.isArray(typ)) return transformEnum(typ, val);
    if (typeof typ === "object") {
        return typ.hasOwnProperty("unionMembers") ? transformUnion(typ.unionMembers, val)
            : typ.hasOwnProperty("arrayItems")    ? transformArray(typ.arrayItems, val)
            : typ.hasOwnProperty("props")         ? transformObject(getProps(typ), typ.additional, val)
            : invalidValue(typ, val);
    }
    // Numbers can be parsed by Date but shouldn't be.
    if (typ === Date && typeof val !== "number") return transformDate(val);
    return transformPrimitive(typ, val);
}

function cast<T>(val: any, typ: any): T {
    return transform(val, typ, jsonToJSProps);
}

function uncast<T>(val: T, typ: any): any {
    return transform(val, typ, jsToJSONProps);
}

function a(typ: any) {
    return { arrayItems: typ };
}

function u(...typs: any[]) {
    return { unionMembers: typs };
}

function o(props: any[], additional: any) {
    return { props, additional };
}

function m(additional: any) {
    return { props: [], additional };
}

function r(name: string) {
    return { ref: name };
}

const typeMap: any = {
    "V1Template": o([
        { json: "start", js: "start", typ: "" },
        { json: "name", js: "name", typ: "" },
        { json: "nodes", js: "nodes", typ: m(r("V1Node")) },
    ], false),
    "V1Node": o([
        { json: "id", js: "id", typ: "" },
        { json: "message", js: "message", typ: u(null, "") },
        { json: "connections", js: "connections", typ: a(r("Connection")) },
    ], false),
    "Connection": o([
        { json: "nodeID", js: "nodeID", typ: "" },
        { json: "optionID", js: "optionID", typ: u(null, "") },
        { json: "optionValue", js: "optionValue", typ: u(null, "") },
        { json: "nextNodeID", js: "nextNodeID", typ: "" },
    ], false),
};




export interface TEMPNodeContentTrackerOptionPopulated extends TEMPNodeContentTrackerOption {
    target?: string
    targetHandle?: string
}





function recurseOnNode (nodeid: string, edges2: Edge<any>[], nodes2: Node<TEMPNodeData>[], nodeContentTracker2: TEMPNodeContentTrackerRef) : V1Nodes {
    
    const currentNodeObj = nodeContentTracker2.current[nodeid];
    const populatedOptions: TEMPNodeContentTrackerOptionPopulated[] = currentNodeObj.options.map((option: TEMPNodeContentTrackerOption) : TEMPNodeContentTrackerOptionPopulated => {
        const populatedOption: TEMPNodeContentTrackerOptionPopulated = {
            content: option.content,
            isEdited: option.isEdited,
            handleID: option.handleID
        };
        populatedOption.targetHandle = "a";
        //option.target = edges2.filter((edge) => edge.sourceHandle === option.handleID && edge.source === nodeid)[0].id;
        const targets = edges2.filter((edge: Edge<any>) => edge.sourceHandle === option.handleID && edge.source === nodeid);
        if(targets.length === 1) {
            populatedOption.target = targets[0].target;
        }
        return populatedOption;
    })

    let nodearr: V1Nodes = {};
    for(const option of populatedOptions) {
        if(option.target !== undefined) {
            const result = recurseOnNode(option.target, edges2, nodes2, nodeContentTracker2)
            nodearr = Object.assign(nodearr, result);
        }
    }

    nodearr[nodeid] = {
        id: nodeid,
        message: currentNodeObj.message,
        connections: populatedOptions.filter((val: TEMPNodeContentTrackerOptionPopulated) => "target" in val && val.target !== null).map((val: TEMPNodeContentTrackerOptionPopulated): Connection => {
            return {
                nodeID: nodeid,
                optionID: val.handleID,
                optionValue: val.content,
                nextNodeID: val.target
            }
        })
    }
    return nodearr;
}

function ExportBotToJSON(startingNodeID: string, edges2: Edge<any>[], nodes2: Node<TEMPNodeData>[], nodeContentTracker2: TEMPNodeContentTrackerRef) : V1Template {
    // Method 1: gets all the target nodes, including isolated islands.
    /*edges.map((edge) => edge.target).filter((target) => {
        const result = nodes.filter((node) => node.id === target);
        console.log(result);
        return result.length === 1 && result[0].type === "messageNode";
    })*/
    // Method 2: Recursive!
    const edgesFromStartNode = edges2.filter((val: Edge) => val.source === startingNodeID);

    if(edgesFromStartNode.length !== 1) {
        throw 'No start node edges found!';
    }

    const nodeAfterStart = edgesFromStartNode[0].target;
    let nodes = recurseOnNode(nodeAfterStart, edges2, nodes2, nodeContentTracker2);
    const tempObj: V1Nodes = {}
    tempObj[startingNodeID] = {
        id: startingNodeID,
        message: null,
        connections: [
            {
                nodeID: startingNodeID,
                optionID: "a",
                optionValue: null,
                nextNodeID: nodeAfterStart
            }
        ]
    }
    nodes = Object.assign(nodes, tempObj)
    return {
        start: startingNodeID,
        nodes: nodes
    }
}

export default ExportBotToJSON;