import { useEffect, useRef, useState } from "react";
import { AiOutlineMinusCircle } from 'react-icons/ai'
import Editable from './Editable';
import { Handle, Position } from 'reactflow';
import React from "react";

export interface MessageNodeOptionProps {
    handleID: string
    isEdited?: boolean
    isMultiline?: boolean
    onChange?: (val?: string) => void
    onDelete?: () => void
    value?: string
    children?: Element | Element[] | never[]
}

interface Stateo {
    content: string,
    isEdited: boolean
}

function MessageNodeOption(props: MessageNodeOptionProps) {
    const handleID = props.handleID;
    const isEdited = props.isEdited ? props.isEdited : false;
    const isMultiline = props.isMultiline ? props.isMultiline : false;
    const onChange = props.onChange ? props.onChange : null;
    const onDelete = props.onDelete ? props.onDelete : null;
    const value = props.value ? props.value : "Option";
    
    const [stateo, setStateo] : [Stateo, React.Dispatch<React.SetStateAction<Stateo>>] = useState({
        content: value,
        isEdited: isEdited
    });

    
    const executeOnChange = (val?: string) => {
        if(onChange != null) {
            onChange(val);
        }
    };

    const setStateoCustom = (providedState: Stateo) => {
        let copiedPerson = Object.assign({}, providedState);
        setStateo(copiedPerson);
        // if(onChange !== null) {
        //     onChange(copiedPerson.content);
        // }
    }

    /*if(stateo.content !== value) {
        stateo.content = value;
        setStateoCustom(stateo);
    }*/

    return (
        <div className={'option-container' + (stateo.isEdited ? " option-container-del-btn-shown" : "")}>
            <AiOutlineMinusCircle tabIndex={-1} className={"delete-option-btn" + (stateo.isEdited ? " delete-option-btn-shown" : "")}></AiOutlineMinusCircle>
            <Editable 
                style={{display: "inline-block"}} 
                value={value} 
                onChange={(newVal) => {
                    //stateo.content = newVal
                    //setStateoCustom(stateo);
                    executeOnChange(newVal);
                }} 
                onStartEdit={() => {
                    stateo.isEdited = true;
                    setStateoCustom(stateo);
                }} 
                onFinishEdit={(val, evv) => {
                    const ev: any = evv;//TODO: Bom!
                    if(ev.relatedTarget != null && ev.relatedTarget.classList.contains("delete-option-btn")) {
                        if(onDelete !== null) {
                            onDelete();
                        }
                    }
                    stateo.isEdited = false;
                    setStateoCustom(stateo);
                }} 
                isMultiline={isMultiline}></Editable>
            <Handle type="source" position={Position.Right} id={handleID} />
        </div>
    )
}

export default MessageNodeOption;