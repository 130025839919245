import React from "react";
import { useState } from "react";

export interface EditableProps {
    value?: string
    onChange?: (val?: string) => void
    onStartEdit?: (val: boolean, ev: React.MouseEvent<HTMLDivElement, MouseEvent> 
        | React.FocusEvent<HTMLInputElement, Element>
        | React.FocusEvent<HTMLTextAreaElement, Element>) => void
    onFinishEdit?: (val: boolean, ev: React.MouseEvent<HTMLDivElement, MouseEvent> 
        | React.FocusEvent<HTMLInputElement, Element>
        | React.FocusEvent<HTMLTextAreaElement, Element>) => void
    style?: any
    isEdited?: boolean
    isMultiline?: boolean
    children?: Element | Element[] | never[]
}

function Editable(props: EditableProps) {
    const value = props.value ? props.value : "Click to edit!";
    const onChange = props.onChange ? props.onChange : null;
    const onStartEdit = props.onStartEdit ? props.onStartEdit : null;
    const onFinishEdit = props.onFinishEdit ? props.onFinishEdit : null;
    const style = props.style ? props.style : {};

    const setInputValueCustom = (val: string) => {
        //setInputValue(val);
        if(onChange != null){
            onChange(val);
        }
    }

    const setIsEditedCustom = (val: boolean, ev: React.MouseEvent<HTMLDivElement, MouseEvent> 
        | React.FocusEvent<HTMLInputElement, Element>
        | React.FocusEvent<HTMLTextAreaElement, Element>) => {
        setIsEdited(val);
        if(onStartEdit != null && val === true){
            onStartEdit(val, ev);
        }
        else if(onFinishEdit != null && val === false){
            onFinishEdit(val, ev);
        }
    }

    const [isEdited, setIsEdited] = useState(props.isEdited ? props.isEdited : false);
    const isMultiline = props.isMultiline ? props.isMultiline : false;

    if(isEdited) {
        if(isMultiline) {
            
            return (
                <textarea value={value} autoFocus onBlur={(e) => setIsEditedCustom(false, e)} onChange={(e) => setInputValueCustom(e.target.value)}></textarea>
            );
        } else {
            return (
                <input type={"text"} autoFocus onBlur={(e) => setIsEditedCustom(false, e)} value={value} onChange={(e) => setInputValueCustom(e.target.value)}></input>
            );
        }
    } else {
        return (
            <div style={style} className={isMultiline ? "message-label" : ""} onClick={(e) => setIsEditedCustom(true, e)}>{value}</div> 
        );
    }
}

export default Editable;