import * as React from 'react';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Typography from '@mui/material/Typography';
import ContentCut from '@mui/icons-material/ContentCut';
import ContentCopy from '@mui/icons-material/ContentCopy';
import ContentPaste from '@mui/icons-material/ContentPaste';
import Cloud from '@mui/icons-material/Cloud';
import { Menu } from '@mui/material';
import Add from '@mui/icons-material/Add';
import DownloadIcon from '@mui/icons-material/Download';
import AlignVerticalTopIcon from '@mui/icons-material/AlignVerticalTop';
import AlignHorizontalLeftIcon from '@mui/icons-material/AlignHorizontalLeft';
import ZoomOutMapIcon from '@mui/icons-material/ZoomOutMap';
import UploadIcon from '@mui/icons-material/Upload';
import ZoomInMapIcon from '@mui/icons-material/ZoomInMap';
import { XYCoords } from '../MainFlow/Flow';



interface IconMenuProps {
  target?: XYCoords,
  onClose?: () => void
  onSelect?: (e: React.MouseEvent<HTMLLIElement, MouseEvent> | React.ChangeEvent<HTMLInputElement>, action: string, target: XYCoords) => void
  children?: Element | Element[] | never[]
}

export default function IconMenu(props: IconMenuProps) {
    const target = props.target ? props.target : null
    const onClose = props.onClose ? props.onClose : null
    const onSelect = props.onSelect ? props.onSelect : null
    const open = target ? true : false;

    const onClick = (e: React.MouseEvent<HTMLLIElement, MouseEvent> | React.ChangeEvent<HTMLInputElement>, action: string) => {
      if(onSelect && target) {
        onSelect(e, action, target);
      }
      if(onClose) {
          onClose();
      }
    }
    
  return (
      <Menu 
        anchorReference="anchorPosition"
        anchorPosition={open && target ? { top: target.y, left: target.x } : undefined}
        PaperProps={{
            sx: {
                width: 320, maxWidth: '100%'
            }
        }}
        open={open}
        onClose={() => {if(onClose)onClose()}}
      >
        <MenuItem onClick={(e) => onClick(e, "add")}>
          <ListItemIcon>
            <Add fontSize="small" />
          </ListItemIcon>
          <ListItemText>Add a node</ListItemText>
          <Typography variant="body2" color="text.secondary">
            a
          </Typography>
        </MenuItem>

        <MenuItem disabled>
          <ListItemIcon>
            <ContentCut fontSize="small" />
          </ListItemIcon>
          <ListItemText>Cut</ListItemText>
          <Typography variant="body2" color="text.secondary">
            CTRL + X
          </Typography>
        </MenuItem>
        <MenuItem disabled>
          <ListItemIcon>
            <ContentCopy fontSize="small" />
          </ListItemIcon>
          <ListItemText>Copy</ListItemText>
          <Typography variant="body2" color="text.secondary">
            CTRL + C
          </Typography>
        </MenuItem>
        <MenuItem disabled>
          <ListItemIcon>
            <ContentPaste fontSize="small" />
          </ListItemIcon>
          <ListItemText>Paste</ListItemText>
          <Typography variant="body2" color="text.secondary">
            CTRL + V
          </Typography>
        </MenuItem>

        <Divider></Divider>
        
        <MenuItem onClick={(e) => onClick(e, "vertical_layout")}>
          <ListItemIcon>
            <AlignVerticalTopIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Vertical Auto-Layout</ListItemText>
          <Typography variant="body2" color="text.secondary">
            v
          </Typography>
        </MenuItem>
        <MenuItem onClick={(e) => onClick(e, "horizontal_layout")}>
          <ListItemIcon>
            <AlignHorizontalLeftIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Horizontal Auto-Layout</ListItemText>
          <Typography variant="body2" color="text.secondary">
            h
          </Typography>
        </MenuItem>

        <Divider></Divider>

        <MenuItem onClick={(e) => onClick(e, "export_json")}>
          <ListItemIcon>
            <DownloadIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Export bot json</ListItemText>
          <Typography variant="body2" color="text.secondary">
            ej
          </Typography>
        </MenuItem>
        <input type="file" id="actual-btn" onChange={(e) => onClick(e, "import_json")} hidden/>
        <label htmlFor="actual-btn">
          <MenuItem>
            <ListItemIcon>
              <UploadIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Import bot json test</ListItemText>
            <Typography variant="body2" color="text.secondary">
              ij
            </Typography>
          </MenuItem>
        </label>
        <MenuItem disabled onClick={(e) => onClick(e, "export_map")}>
          <ListItemIcon>
            <ZoomOutMapIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Export bot map</ListItemText>
          <Typography variant="body2" color="text.secondary">
            em
          </Typography>
        </MenuItem>
        <MenuItem disabled onClick={(e) => onClick(e, "import_map")}>
          <ListItemIcon>
            <ZoomInMapIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Import bot map</ListItemText>
          <Typography variant="body2" color="text.secondary">
            im
          </Typography>
        </MenuItem>

        {/* <MenuItem>
          <ListItemIcon>
            <ContentCut fontSize="small" />
          </ListItemIcon>
          <ListItemText>Cut</ListItemText>
          <Typography variant="body2" color="text.secondary">
            CTRL + X
          </Typography>
        </MenuItem>
        <MenuItem>
          <ListItemIcon>
            <ContentCopy fontSize="small" />
          </ListItemIcon>
          <ListItemText>Copy</ListItemText>
          <Typography variant="body2" color="text.secondary">
            CTRL + C
          </Typography>
        </MenuItem>
        <MenuItem>
          <ListItemIcon>
            <ContentPaste fontSize="small" />
          </ListItemIcon>
          <ListItemText>Paste</ListItemText>
          <Typography variant="body2" color="text.secondary">
            CTRL + V
          </Typography>
        </MenuItem>
        <Divider />
        <MenuItem>
          <ListItemIcon>
            <Cloud fontSize="small" />
          </ListItemIcon>
          <ListItemText>Web Clipboard</ListItemText>
        </MenuItem> */}
      </Menu>
  );
}