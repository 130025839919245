import { Fab } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { v4 as uuidv4 } from 'uuid';
import { useCallback, useRef, useState } from 'react';
import dagre from 'dagre';
import ReactFlow, {
  MiniMap,
  Controls,
  Background,
  useNodesState,
  useEdgesState,
  addEdge,
  useReactFlow,
  applyNodeChanges,
  NodeChange,
  OnConnect,
  Connection,
  Edge,
  OnConnectStart,
  OnConnectStartParams,
  OnConnectEnd,
} from 'reactflow';
// 👇 you need to import the reactflow styles
import 'reactflow/dist/style.css';
import MessageNode, { TEMPMessageNodeState, TEMPNodeContentTrackerOption } from '../MessageNode/MessageNode';
import './MessageNode.css';
import IconMenu from '../ContextMenu/IconMenu';
import ExportBotToJSON, { Convert } from '../Export/ExportJson';
import { getLayoutedElements } from './DagreIntegration';
import { MouseEvent } from 'react';
import React from 'react';

export interface TEMPNodeData {
  label?: string;
  undeletable?: boolean;
  test?: any;
  initialValue?: TEMPMessageNodeState
  onDelete?: () => void
  onChange?: (val: TEMPMessageNodeState) => void
}

export interface XYCoords {
  y: number,
  x: number
}

interface MyNode {
  id: string
  position: XYCoords
  data: TEMPNodeData
  type: string
}

interface MyEdge { 
  id: string
  source: string
  sourceHandle: string | null
  targetHandle: string
  target: string
}

export interface TEMPNodeContentTracker {
  [index:string] : TEMPMessageNodeState
}

export interface TEMPNodeContentTrackerRef {
  current: {
      [index:string] : TEMPMessageNodeState
  }
}

interface ConnectingNode { 
  clickCanceled?: boolean
  canceled?: boolean
  nodeId: string
  handleId: string | null
}

interface ConnectingNodeRef {
  current: ConnectingNode | null
}

interface ReactFlowWrapperRef {
  current: Element | null
}

interface ContextMenu {
  currPos: XYCoords | null
}

const initialNodes: MyNode[] = [
  { id: 'start', position: { x: 0, y: 0 }, data: { label: 'Start', undeletable: true }, type: 'input' },
  { id: '2', position: { x: 100, y: 100 }, data: { test:"wow" }, type: 'messageNode' }
];

const initialEdges: MyEdge[] = [{ id: 'e-start-2', source: 'start', sourceHandle: null, targetHandle: 'a', target: '2' }];

const nodeTypes = { messageNode: MessageNode };

function Flow() {
  const reactFlowWrapper: ReactFlowWrapperRef = useRef(null);
  const reactFlowInstance = useReactFlow();

  const connectingNodeIdNormal: ConnectingNodeRef = useRef(null);
  const connectingNodeIdClick: ConnectingNodeRef = useRef(null);

  const nodeContentTracker: TEMPNodeContentTrackerRef = useRef({});


  const [nodes, setNodes, onNodesChangee] = useNodesState(initialNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);

  // Don't allow deletion of undeletable nodes
  const onNodesChange = useCallback((changes: NodeChange[]) => 
    setNodes((nds) => {
      const parsedChanges2 = changes.filter((change) => {
        const validChange = change.type !== 'remove' || (change.type === 'remove' && nodes.find(n => n.id === change.id)?.data.undeletable !== true );
        if (validChange) {
          return true;
        }
        return false;
      }, []);

      return applyNodeChanges(parsedChanges2, nds)
  }),
    [setNodes]
  );

  const { project } = useReactFlow();

  const defContextMenu: ContextMenu = {
    currPos: null
  }
  const [contextMenu, setContextMenu] = useState(defContextMenu)

  const deleteNodeById = (id: string) => {
    setNodes(nodes => nodes.filter(node => node.id !== id));
    setEdges(edges => edges.filter(edge => edge.source !== id && edge.target !== id));
    delete nodeContentTracker.current[id];
  };

  for(const node of nodes) {
    node.data.onDelete = () => deleteNodeById(node.id);
    node.data.onChange = (newVal: TEMPMessageNodeState) => {
      // Update the store containing the current values of the options.
      nodeContentTracker.current[node.id] = newVal;
    }
  }

  const onConnect = useCallback((params: Connection) => setEdges((eds: Edge<any>[]) => {
    
    // Cancel events that we don't want to happen (Weirdly called and provides a verdict when connecting 2 existing nodes, as opposed to connecting a node to an empty canvas space, where onConnectEnd is the verdict (because we overrided functionality there))
    

    let flag = false;
    if(connectingNodeIdClick.current?.canceled) {
      console.log("Click flag is canceled")
      connectingNodeIdClick.current.canceled = false;
      flag = true;
    }
    if(connectingNodeIdNormal.current?.canceled) {
      console.log("Normal flag is canceled")
      connectingNodeIdNormal.current.canceled = false;
      flag = true;
    }

    console.log("onConnect", flag);
    if(flag) {
      return eds;
    }


    return addEdge(params, eds);
  }), [setEdges]);

  const onConnectStart = useCallback((event: MouseEvent, params: OnConnectStartParams, isClick=false) : void => {
    let connectingNodeId: ConnectingNodeRef;
    if(isClick) {
      connectingNodeId = connectingNodeIdClick;
      console.log("Click one!");
    }else {
      connectingNodeId = connectingNodeIdNormal;
      console.log("No Click one!");
    }
    // When the user starts to draw an edge from the source handler, this callback is called.
    // This callback determines if the source handler already has connections, and cancels the event if it does, otherwise, stores the source handler for use in onConnectEnd later.
    let nodeId = params.nodeId;
    let handleId = params.handleId;
    if(!nodeId) {
      return;
    }
    const canceled = edges.filter((wow) => wow.source === nodeId && wow.sourceHandle === handleId).length > 0;
    console.log("onConnectStart canceled:", canceled);
    connectingNodeId.current = { canceled: canceled, nodeId: nodeId, handleId: handleId };
  }, [edges]);

  const onClickConnectStart = (event: MouseEvent, params: OnConnectStartParams) => {
    return onConnectStart(event, params, true);
  };

  const onConnectEnd  = useCallback(
    // When the user ends an edge draw, either at a destination handler or on an empty canvas space, this callback is called.
    // When the end of the edge is placed on an existing handler, the verdict is at the onConnect callback, and overriding functionality should happen there
    // When the end of the edge is placed on an empty canvas space, this callback creates a new node automatically at that position, and connects
    // the edge to the newly created node.
    (event: any | MouseEvent, isClick=false): void => {
      let connectingNodeId: ConnectingNodeRef;
      if(isClick) {
        connectingNodeId = connectingNodeIdClick;
        console.log("Click two!");
      }else {
        connectingNodeId = connectingNodeIdNormal;
        console.log("No click two!");
      }
      // Cancel the event if the handler started wrong.
      console.log("onConnectEnd canceled:", connectingNodeId.current?.canceled)
      if(connectingNodeId.current?.canceled) {
        return;
      }

      const target: any = event.target;//TODO: A cheat i know

      const targetIsPane = target.classList.contains('react-flow__pane');

      // This part handles a case where the edge ends in an empty space - automatically creating a new node and attaching to it.
      if (targetIsPane && reactFlowWrapper.current && connectingNodeId.current) {
        // we need to remove the wrapper bounds, in order to get the correct position
        const { top, left } = reactFlowWrapper.current.getBoundingClientRect();
        const id = uuidv4();
        const edgeID = uuidv4();
        const newNode = {
          id: id,
          position: project({ x: event.clientX - left - 3, y: event.clientY - top - 155 }),
          type: 'messageNode',
          data: {}
        };

        setNodes((nds) => nds.concat(newNode));
        setEdges((eds) => eds.concat({ id: edgeID, source: connectingNodeId.current!.nodeId, sourceHandle: connectingNodeId.current!.handleId, target: id, targetHandle: "a" }));
      }
    },
    [project]
  );

  const onClickConnectEnd = (event: any | MouseEvent) => {
    return onConnectEnd(event, true);
  };

  

  const onLayout = useCallback(
    (direction: string) => {
      const widths: any = reactFlowInstance.getNodes().map((a) => a.width).filter((value) => (typeof value === 'number' && isFinite(value)) );
      const maxWidth = Math.max(...widths);

      const heights: any = reactFlowInstance.getNodes().map((a) => a.height).filter((value) => (typeof value === 'number' && isFinite(value)) );
      const maxHeight = Math.max(...heights);

      const { nodes: layoutedNodes, edges: layoutedEdges } = getLayoutedElements(
        nodes,
        edges,
        direction,
        maxWidth,
        maxHeight
      );

      setNodes([...layoutedNodes]);
      setEdges([...layoutedEdges]);
    },
    [nodes, edges]
  );

  const getReactFlowWrapperRef = (): any => {
    return reactFlowWrapper;
  }

  return (
    <div className="wrapper" ref={getReactFlowWrapperRef()}>
      <ReactFlow
        nodes={nodes}
        onNodesDelete={(e) => {
          console.log("on delete", e);
          return false;
        }}
        edges={edges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onConnect={onConnect}
        nodeTypes={nodeTypes}
        onConnectStart={onConnectStart}
        onConnectEnd={onConnectEnd}
        onClickConnectStart={onClickConnectStart}
        onClickConnectEnd={onClickConnectEnd}
        fitView
        onPaneContextMenu={(params) => {
          setContextMenu({
            currPos: {
              x: params.clientX,
              y: params.clientY
            }
          });
          params.preventDefault();
          return false;
        }}
      >
        <MiniMap zoomable pannable />
        <Controls />
        <Background />
        {/* <Fab className="floating-add" color="primary" aria-label="add">
          <AddIcon />
        </Fab> */}
        <IconMenu target={contextMenu.currPos || undefined} 
          onClose={() => {
            setContextMenu({
              currPos: null
            })
          }}
          onSelect={(e, item, selectionTarget) => {
            switch(item) {
              case "add":
                if(!reactFlowWrapper.current) {
                  break;
                }
                const { top, left } = reactFlowWrapper.current.getBoundingClientRect();
                setNodes((nds) => nds.concat({
                  id: uuidv4(),
                  position: project({ x: selectionTarget.x - left - 75, y: selectionTarget.y - top - 20 }), 
                  type: 'messageNode',
                  data: {}
                }));
                break;
              case "export_json":
                console.log(ExportBotToJSON("start", edges, nodes, nodeContentTracker));
                const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(
                  JSON.stringify(ExportBotToJSON("start", edges, nodes, nodeContentTracker))
                )}`;
                const link = document.createElement("a");
                link.href = jsonString;
                link.download = "data.json";
            
                link.click();
                break;
              case "import_json":
                console.log("wo", e);
                const target: any = e.target;
                if(target?.files?.length === 1) {
                  console.log("wo", target.files[0].text().then((txt: string) => {
                    const robot = Convert.toV1Template(txt);
                    const newNodes: MyNode[] = [
                      { 
                        id: 'start', 
                        position: { x: 0, y: 0 }, 
                        data: { 
                          label: 'Start', 
                          undeletable: true 
                        }, 
                        type: 'input' }
                    ];
                    const newEdges: MyEdge[] = [

                    ]
                    for(const [key, value] of Object.entries(robot.nodes)) {
                      if(value.id === robot.start) {
                        newEdges.push({ id: 'e-start-' + value.connections[0].nextNodeID, source: 'start', sourceHandle: null, targetHandle: 'a', target: value.connections[0].nextNodeID! })
                        continue;
                      }
                      const state: TEMPMessageNodeState = {
                        message: value.message!,
                        options: value.connections.map((a): TEMPNodeContentTrackerOption => {
                          return {
                            content: a.optionValue!,
                            handleID: a.optionID!,
                            isEdited: false
                          }
                        })
                      }
                      newNodes.push({
                        id: value.id, 
                        position: { x: 0, y: 0 }, 
                        data: { 
                          initialValue: state
                        }, 
                        type: 'messageNode'
                      });

                      for(const connection of value.connections) {
                        newEdges.push({
                          id: uuidv4(),
                          source: value.id,
                          sourceHandle: connection.optionID!,
                          target: connection.nextNodeID!,
                          targetHandle: "a"
                        });
                      }
                    }
                    console.log(newNodes);
                    console.log(newEdges);
                    setNodes(newNodes);
                    setEdges(newEdges);
                  }));
                }
                break;
              case "vertical_layout":
                onLayout('TB');
                break;
              case "horizontal_layout":
                onLayout('LR');
                break;
            }
          }}
        >

        </IconMenu>
      </ReactFlow>
    
    </div>
  );
}


export default Flow;