import { useCallback, useEffect, useRef, useState } from 'react';
import { Handle, Position } from 'reactflow';
import { AiFillMinusCircle } from 'react-icons/ai'
import Editable from './Editable';
import MessageNodeOption from './MessageNodeOption';
import React from 'react';

interface MessageNodeData {
  value?: TEMPMessageNodeState
  initialValue?: TEMPMessageNodeState
  onDelete?: () => void
  onChange?: (val: TEMPMessageNodeState) => void
}

interface MessageNodeProps {
  data: MessageNodeData
}

export interface TEMPNodeContentTrackerOption {
  content: string;
  handleID?: string;
  isEdited: boolean;
}

export interface TEMPMessageNodeState {
  message: string,
  options: TEMPNodeContentTrackerOption[]
}

const DEFAULT_MESSAGE_NODE_STATE: TEMPMessageNodeState = {
  message: "Message",
  options: [
    {
      content: "Option 1", 
      isEdited: false
    }, 
    {
      content: "Option 2", 
      isEdited: false
    }, 
    {
      content: "Option 3", 
      isEdited: false
    }, 
    {
      content: "Option 4", 
      isEdited: false
    }
  ]
}

function MessageNode(props: MessageNodeProps) {

  const onChange = props.data.onChange ? props.data.onChange : (_: TEMPMessageNodeState) => {console.log("empty!")};
  const onDelete = props.data.onDelete ? props.data.onDelete : () => {console.log("empty!")};


  const value = props.data.value ? props.data.value : null;// A value that is set and updated by the parent
  const initialValue = props.data.initialValue ? props.data.initialValue : null;// A value that is initially set by the parent, but updated and managed internally.
  
  const currValue: TEMPMessageNodeState = value !== null ? value : 
  (// currValue is the verdict value from "value" and "initialValue", preferring the former.
    initialValue !== null ? initialValue : DEFAULT_MESSAGE_NODE_STATE
  );

  //const [message, setMessage] = useState("Message");
  //const [options, setOptions] = useState([{content: "Option 1", isEdited: false}, {content: "Option 2", isEdited: false}, {content: "Option 3", isEdited: false}, {content: "Option 4", isEdited: false}]);
  const [info, setInfo] = useState(currValue);

  const setCustomInfo = (newVal: TEMPMessageNodeState) => {
    let copiedPerson = Object.assign({}, newVal);
    if(onChange) {
      onChange(copiedPerson);
    }
    setInfo(copiedPerson);
  };

  const addHandlerID = (infoObj: TEMPMessageNodeState) => {
    let copiedPerson = Object.assign({}, infoObj);
    copiedPerson.options = copiedPerson.options.map((val, key) => {
      if(val.handleID) {
        return val;
      }
      val.handleID = "o" + key + "_handle";
      return val;
    })
    return copiedPerson;
  }

  const getInfo = () => {
    if(value === null) {
      return addHandlerID(info);
    } else {
      return addHandlerID(currValue);
    }
  };

  useEffect(() => {
    if(onChange) {
      onChange(currValue)
    }
  }, [])


  return (
    <div className="text-updater-node">
      <AiFillMinusCircle className='delete-node-btn' onClick={() => onDelete()}></AiFillMinusCircle>
      <div className='text-updater-node-inner'>
        <Editable 
          value={getInfo().message} 
          onChange={(newVal) => {
            if(!newVal) {
              return;
            }
            const tempInfo = getInfo();
            tempInfo.message = newVal;
            setCustomInfo(tempInfo)
          }}
          isMultiline={true}>

        </Editable>
        <br></br>
        {
          getInfo().options.map((val, key) => {
            const baseid = "o" + key;
            return (
              <MessageNodeOption 
                key={baseid} 
                handleID={val.handleID!} 
                value={val.content} 
                onDelete={() => {
                  const tempInfo = getInfo();
                  tempInfo.options.splice(key, 1);
                  setCustomInfo(tempInfo);
                }}
                onChange={(newVal) => {
                  if(!newVal) {
                    return;
                  }
                  const tempInfo = getInfo();
                  tempInfo.options[key].content = newVal;
                  setCustomInfo(tempInfo);
                }}
                >
              </MessageNodeOption>
            );
          })
        }
      </div>
      <Handle type="target" position={Position.Left} id="a" />
      <div onClick={() => {
        const tempInfo = getInfo();
        tempInfo.options.push({content: "Option " + (tempInfo.options.length + 1), isEdited: false});
        setCustomInfo(tempInfo);
      }} className='bottom-button'><span>+</span></div>
    </div>
  );
}

export default MessageNode;