import logo from './logo.svg';
import './App.css';
import Flow from './MainFlow/Flow';
import { ReactFlowProvider } from 'reactflow';

function App() {
  return (
    <div className="App">
      <ReactFlowProvider >
      <Flow></Flow>
      </ReactFlowProvider>
    </div>
  );
}

export default App;
